<!-- @format -->

<div class="flex items-center justify-center w-full h-full">
	<svg
		class="block"
		[attr.width]="square || width"
		[attr.height]="square || height"
		[attr.viewBox]="viewBox"
	>
		<use [attr.xlink:href]="'assets/icons/sprite-new.svg#' + icon"></use>
	</svg>
</div>
