<!-- @format -->

<footer
	class="bg-light-2 bottom-bar-shadow"
	[ngClass]="{'hidden md:block': isHideOnMobile}"
>
	<div
		class="mx-auto flex w-full max-w-[1560px] flex-col gap-3 p-4 md:flex-row md:items-start md:justify-between md:p-6"
	>
		<div class="flex items-center justify-between gap-8">
			<a routerLink="/">
				<img src="/assets/images/shared/logo-2.svg" alt="Литнет" loading="lazy" />
			</a>
			<div class="text-on-light-1 flex items-center space-x-2.5">
				<span class="text-caption-md-semibold">© 2023-{{ currentYear }}, Литнет</span>
				<!--
				<a href="https://vk.com">
            <i appSvgIcon appIcon="vk-logo" appSquare="20"></i>
        </a>
        -->
			</div>
		</div>
		<button
			class="bg-primary-50 bg-[url(/assets/images/shared/bg-help.png)] bg-cover bg-no-repeat flex flex-col items-center justify-center gap-2 rounded-lg py-3 px-4 md:flex-row md:gap-1"
			(click)="onCopy('help@lit-era.com')"
		>
			<span id="support-email" class="text-body-mobile-xl-medium text-neutral-3000">
				help@lit-era.com
			</span>
			<span class="text-caption-sm-semibold text-neutral-400">техническая поддержка</span>
		</button>
	</div>
	<div class="border-light-3 border-t">
		<div class="mx-auto grid max-w-[1560px] grid-cols-3 gap-1 p-4 md:px-6">
			<div class="col-span-3 flex flex-wrap gap-1 md:col-span-1 md:text-left">
				<span class="text-body-mobile-md-regular text-neutral-600">ООО «Литнет»</span>
				<span class="text-body-mobile-md-regular text-neutral-600">ИНН: 9704145094</span>
				<span class="text-body-mobile-md-regular text-neutral-600">ОГРН: 1227700355563</span>
			</div>
			<a
				class="text-body-mobile-md-medium md:text-body-mobile-xl-medium text-neutral-3000 col-span-3 md:col-span-1 md:text-center"
				[routerLink]="['/user-agreement']"
			>
				Пользовательское соглашение
			</a>
			<a
				class="text-body-mobile-md-medium md:text-body-mobile-xl-medium text-neutral-3000 col-span-3 md:col-span-1 md:text-right"
				[routerLink]="['/privacy-policy']"
			>
				Политика конфиденциальности
			</a>
		</div>
	</div>
</footer>
